import FaqModel from '@/views/service/faq/model/FaqModel';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class FaqDetailViewModel {
  constructor() {
    this.model = new FaqModel();
    this.isModify = false;
  }

  init(id){
    if(id){
      this.getFaqDetail(id)
    }
  }
  onModifyOrder(orderData){
    console.log(orderData)
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteFaqCategory();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `FAQ를 삭제할까요?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  
  onClickCompelete(){
    if(this.isModify){
      this.putFaqDetail()
    }else{
      this.postFaqDetail()
    }
  }

  getFaqDetail(id){
    const path = `${apiPath.FAQ_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  postFaqDetail(){
    const path = `${apiPath.FAQ_REGISTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path, data).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', 'FAQ가 추가되었습니다');
      router.push({ name: 'SERVICE_FAQ_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  putFaqDetail(){
    const data = this.model.getModifyData();
    const path = `${apiPath.FAQ_DETAIL.format(data.id)}`;
    PUT_REQUEST(path,data).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', 'FAQ가 수정되었습니다');
      router.push({ name: 'SERVICE_FAQ_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  deleteFaqCategory(){
    const path = `${apiPath.FAQ_DELETE.format(this.model.id)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', `FAQ가 삭제되었습니다.`);
      router.push({ name: 'SERVICE_FAQ_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}