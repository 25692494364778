<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>카테고리<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              :dataList="categoryViewModel.dataList | setDataList('id', 'name')"
              :value.sync="viewModel.model.categoryId"
              maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>Q. 질문<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="질문을 입력해주세요."
              :value.sync="viewModel.model.question"/>
          </td>
        </tr>
        <tr>
          <th>A. 답변<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <EditorTiptap
              :menuDataList="['bold','italic','strike','underline','horizontalRule','highlight','link','bulletList','orderedList','blockquote','clear_format','undo','redo']"
              :maxLength="10000"
              :value.sync="viewModel.model.answer"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="SERVICE_FAQ_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickCompelete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import FaqDetailViewModel from '@/views/service/faq/viewModel/FaqDetailViewModel'
import FaqCategoryViewModel from '@/views/service/faq/viewModel/FaqCategoryViewModel'

export default {
  name:'TermRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    EditorTiptap,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
    this.viewModel.isModify = this.$route.meta.isModify;
    this.categoryViewModel.init();
  },
  data(){
    return{
      viewModel: new FaqDetailViewModel(),
      categoryViewModel: new FaqCategoryViewModel(),
    }
  },
}
</script>